
.magazines-box{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px;
    padding: 40px;
}

.cards{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

}