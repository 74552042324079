@import '../../style.css';

.footer-div {
    background-color: var(--black);
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    height: 64px;
    gap: 16px;
}
.logos{
    display: flex;
    align-items: center;
    gap: 24px;
}
.social-media-icon{
    color: var(--white);
    gap: 24px;
}

.social-media-icon:hover, .signature:hover{
    opacity: 0.8;
    cursor: pointer;
}
