.event-card-link{
    text-decoration: none;
}

.event-card{
    width: 200px;
    height: 250px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--grey);
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0 0 8px rgb(48, 47, 47);
    transition: transform 0.2s ease-in-out;
}

.event-card:hover{
    transform: scale(1.09);
    box-shadow: 0 4px 8px var(--white);
    cursor: pointer;
    
}

.event-photo{
   width: 100%;
   height: 80%;
   border-radius: 10px;
   box-shadow: 0 0 8px var(--white);
}

.event-details{
    background: none;
    color: var(--black);
}

.event-title{
    background: none    ;
}

