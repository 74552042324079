.team-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-bottom: 50px;

}

.red {
    color: var(--red);
}

.group {
    width: 80%;
    display: flex;
    flex-direction: row;
}

.teamtitle {
    color: var(--red);
    width: 50%;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
}



.teamtitle h1 {
    margin: 0;
    /* Reset margin to remove any default spacing */
}


.people-div {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: center;
}

.ttright {
    text-align: end;
}

.top {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px;
}





@media screen and (max-width: 480px) {

    .top {
        flex-direction: column;
    }

    .group {
        flex-direction: column;
        align-items: center;
    }

    .right {
        flex-direction: column-reverse;
        align-items: center;
    }

    .ttright {
        text-align: center;
    }

    .teamtitle {
        width: 50%;
        align-self: center;
        text-align: justify;

    }

    .teamtitle h1 {
        font-size: 26px;
    }
}