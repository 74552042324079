/* PhotoGallery.css */

.gallery-container {
  width: 80%;
  margin: auto;
}

.swiper-container {
  width: 80%;
  position: relative;
  overflow: hidden;
  padding: 64px;

}

.gallery-photo {
  width: 100%;
  height: 301px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(35, 35, 35, 0.235);
}

.slider-controler {
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  background: none;
  text-shadow: 4px 0 5px var(--white);
}