.contact-box {
    height: fit-content;
    width: 60%;
    color: var(--white);
    background: var(--grey);
    margin: 16px;
    padding: 24px;
    border-radius: 10px;
    text-align: center;
}

.mail-link {
    color: var(--ligth-red);
}

.contact-content {
    background: none;
}

.map {
    border-radius: 10px;
    width: 250px;
}

@media screen and (max-width: 480px) {
    .map {
        width: 75%;
    }
}