@import '../../style.css';

* {
    box-sizing: border-box;
}

/* TRAVEL CONNECT IMPROVE & UYE OL */
.eestec__hero-1 {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://istanbul.eestec.net/img/photos/istanbul-homepage-1.jpg");
    background-size: cover;
    background-position: center;
}

.eestec__hero_container-1 {
    margin-top: 0.5rem;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    background: none;

}

.eestec__hero_container-2 {
    background: none;
}

.eestec__hero_bilgi {
    background: none;
}

.eestec__hero_bilgi h3,
p {
    background: none;
}



.eestec__hero_slogan {
    margin-left: 13.5rem;
    margin-top: 4.5rem;
    font-size: 1.875rem;
    color: var(--white);
    margin-bottom: 3.5rem;
    background: none;

}

.eestec__hero_slogan h2 {
    margin-bottom: 0;
    margin-top: 1rem;
    font-weight: 400;
    text-shadow: 0.5px 0.5px 0.5px grey;
    background: none;
}

.eestec__hero_uye-ol {
    margin-right: 15rem;
    margin-top: 4.5rem;
    background-color: var(--red);
    border-radius: 30px;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
    cursor: pointer;
    border-color: var(--red);
}

.eestec__hero_uye-ol a {
    padding: 0;
    margin: 0;
    color: var(--white);
    background-color: var(--red);
    font-size: 1rem;
    letter-spacing: 1.5px;
    text-decoration: none;
}


@media screen and (max-width: 1050px) {
    .eestec__hero_slogan {
        margin-left: 10rem;
    }

    .eestec__hero_uye-ol {
        margin-right: 11.5rem;
    }
}

@media screen and (max-width: 900px) {
    .eestec__hero_slogan {
        margin-left: 7.5rem;
    }

    .eestec__hero_uye-ol {
        margin-right: 8rem;
    }
}

@media screen and (max-width: 780px) {
    .eestec__hero_slogan {
        margin-left: 5.5rem;
    }

    .eestec__hero_uye-ol {
        margin-right: 4.5rem;
    }
}

@media screen and (max-width: 700px) {
    .eestec__hero_slogan {
        margin-left: 4.5rem;
    }

    .eestec__hero_uye-ol {
        margin-right: 2rem;
    }
}

@media screen and (max-width: 625px) {
    .eestec__hero_container-1 {
        flex-direction: column;
    }

    .eestec__hero_slogan {
        margin-left: 0;
        margin-bottom: 1.25rem;
    }

    .eestec__hero_uye-ol {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}

/* ************************************** */

/* BIZ KIMIZ, NELER YAPIYORUZ, NASIL UYE OLUNUR? */

.eestec__hero_container-2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    flex-direction: row;
}

.eestec__hero_bilgi {
    width: calc(33.33% - 20px);
    margin-bottom: 1.25rem;
    flex: 1;
    padding: 1.25rem;
}

.eestec__hero_bilgi:first-child {
    margin-left: 1.75rem;
}

.eestec__hero_bilgi:last-child {
    margin-right: 1.75rem;
}

@media screen and (max-width: 768px) {

    .eestec__hero_container-2 {
        flex-direction: column;
    }

    .eestec__hero_bilgi {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .eestec__hero_bilgi:first-child {
        margin-left: 0;
    }

    .eestec__hero_bilgi:last-child {
        margin-right: 0;
    }

    .eestec__hero_bilgi p {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.eestec__hero_bilgi h3 {
    text-align: center;
    color: var(--red)
}

.eestec__hero_bilgi p {
    text-align: justify;
    color: var(--white);
    letter-spacing: 0.5px;
    font-weight: 300;
    font-size: 15px;
}

.break {
    margin-top: 0.8rem;
    margin-bottom: 2rem;
}

/* ************************************** */

/* BIZ KIMIZ, NELER YAPIYORUZ, NASIL UYE OLUNUR? */

.eestec__hero-2 {
    position: relative;
    z-index: 1;
}

.eestec__etkinlikler {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eestec__etkinlikler h3 {
    font-size: 1.7rem;
    color: var(--white);
    font-weight: 450;
    margin-bottom: 0.5rem;
}

.eestec__hero-2_button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.eestec__daha-fazlasi {
    background-color: var(--red);
    border-radius: 30px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-bottom: 5rem;
    cursor: pointer;
    border-color: var(--red);

}

.eestec__daha-fazlasi p {
    background-color: var(--red);
    color: var(--white);
    font-size: 1rem;
    letter-spacing: 1.25px;

}

.eestec__daha-fazlasi a {
    text-decoration: none;

}




/* ************************************** */

.eestec__hero-2_tanitim {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    flex-direction: row;
}

.eestec__nedir_tanitim {
    flex: 1;
    margin: 0 1.25rem;
    padding: 1.25rem;
    background: none;
}

.yazi {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("http://localhost:3000/img/photos/toplufoto.jpeg");
    background-size: cover;
    background-position: center;
}

.eestec__hero-2_tanitim h3,
p {
    background: none;
}

@media screen and (max-width: 946px) {
    .eestec__hero-2_tanitim {
        flex-direction: column;
        align-items: center;
    }

    .eestec__nedir_tanitim h3 {
        margin-bottom: 2.5rem;
    }

    .eestec__nedir_tanitim p {
        margin-bottom: 1.825rem;
    }

    .eestec__nedir_tanitim {
        margin: 0;
        padding: 1.25rem;
        width: auto;
    }
}

.yazi h3 {
    text-align: center;
    color: var(--red);
    /* border: 1px solid var(--white); */
    font-size: 1.825rem;
    font-weight: 425;
}

.yazi p {
    text-align: justify;
    color: var(--white);
    letter-spacing: 0.5px;
    font-weight: 300;
}

.eestec__magazine_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px;
    padding: 40px;
}

.eestec__magazine_cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}