@import '../../style.css';

.eestec__navbar {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 3.5rem;
    padding-right: 3rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background: var(--black);

    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);

    z-index: 2;

}

.eestec__navbar-links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eestec__navbar-links_logo {
    margin-left: 1rem;
    margin-right: 2rem;
}

.eestec__navbar-links_logo img {
    width: 7.5rem;
    height: auto;
}

.eestec__navbar-links_logo img:hover {
    cursor: pointer;
    opacity: 0.8;
}

.eestec__navbar-links_container {
    display: flex;
    flex-direction: row;
    margin-right: 3rem;
}

.eestec__navbar-links_container a {
    text-decoration: none;
    color: var(--white);
}


.eestec__navbar-links_container p{
    font-weight: 450;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

/* MOBILE PART */

.eestec__navbar-menu {
    margin-left: 1rem;
    margin-right: 1.5rem;
    
    display: none;
    position: relative;
}

.eestec__navbar-menu svg {
    cursor: pointer;
}

.eestec__navbar-menu svg:hover {
    opacity: 0.8;
}


@media screen and (max-width: 1050px) {
    .eestec__navbar-links_container {
        display: none;
    }

    .eestec__navbar-menu {
        display: flex;
    }

    .eestec__navbar-menu_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 6.5rem;
        right: 0rem;
        width: 55%;
        height: 100vh;
        z-index: 1;
    }

    .eestec__navbar-menu_container a {
        text-decoration: none;
        color: var(--white);
    }

    .eestec__navbar-menu_container a:hover {
        opacity: 0.8;
    }

    .eestec__navbar {
        padding-left: 2.8rem;
    }
}

@media screen and (max-width: 700px) {
    .eestec__navbar {
        padding: 1rem 2.8rem;
    }

    .eestec__navbar-menu_container {
        top: 7.5rem;
    }

}

@media screen and (max-width: 550px) {
     .eestec__navbar {
        /*padding-bottom: 1.5rem;
        padding-top: 1.5rem;*/
        padding-left: 1.5rem;
        padding-right: 2.2rem;
    }
}
